import {UserService} from './../../services/user.service';
import {Component, OnInit} from '@angular/core';
import {Validators, FormBuilder} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {CommonService} from 'src/app/services/common.service';
import {ToastMessagesService} from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  password = '';
  confirm_password = '';
  hide = true;
  hide_confirm = true;
  user;

  form = this.formBuilder.group({
    password: [
      '',
      Validators.compose([
        Validators.minLength(5),
        Validators.required
      ]),
    ],
    confirm_password: [
      '',
      Validators.compose([
        Validators.minLength(5),
        Validators.required,
      ]),
    ],
  });

  constructor(private toastMessagesService: ToastMessagesService,
              private commonService: CommonService,
              private userService: UserService,
              public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
              public formBuilder: FormBuilder) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.commonService.getRequest(`user/me`);
  }

  closeDialog(data: any = false) {
    this.dialogRef.close(data);
  }

  validPassword(password) {
    let re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$');
    return re.test(password);
  }

  validFields(error = false) {
    if (this.form.valid) {
      return (this.form.get('password')?.value && this.form.get('confirm_password')?.value)
        && (this.form.get('password')?.value === this.form.get('confirm_password')?.value);
    }
    if (error) {
      if (this.form.get('password')?.value && this.form.get('confirm_password')?.value) {
        return (this.form.get('password')?.value && this.form.get('confirm_password')?.value)
          && (this.form.get('password')?.value === this.form.get('confirm_password')?.value);
      } else {
        return true;
      }
    }
    return false;
  }

  onPasswordChange() {
    if (this.form.get('password')?.value === this.form.get('confirm_password')?.value) {
       this.form.get('confirm_password').setErrors(null);
    } else {
      this.form.get('confirm_password').setErrors({mismatch: true});
    }
  }


  async submit() {
    try {
      this.user.password = this.form.get('password')?.value;
      await this.commonService.putRequest(`user/password`, this.user);
      this.closeDialog(true);
    } catch (error) {
      this.closeDialog(false);
    }
  }

}
