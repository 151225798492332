import { MainDetailsTabComponent } from './../main-details-tab/main-details-tab.component';
import {Component, OnInit, ViewChild} from '@angular/core';
import {TransactionService} from '../../services/transaction.service';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  transactions: any;
  data = [];
  dataLoaded: boolean;
  complete_dataLoaded: boolean;
  pendingData = [];
  users: any;
  displayVersionUpdates: boolean;
  @ViewChild('pendingTable') pendingTable: MainDetailsTabComponent;
  @ViewChild('completeTable') completeTable: MainDetailsTabComponent;
// test eirini
  constructor(private transactionService: TransactionService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.dataLoaded = false;
    this.displayVersionUpdates = false;
  }

  async ngOnInit() {
    await this.initData();
  }

  async initData() {
    try {
      this.dataLoaded = false;

      this.pendingData = await this.transactionService.getPendingOrders();

      if (this.pendingData.length > 0) {
        this.dataLoaded = true;
      }

    } catch (e) {
      this.dataLoaded = true;
      console.log(e);
    }
  }

  async initCompleteData() {
    try {
      this.complete_dataLoaded = false;

      this.data = await this.transactionService.getCompletedOrders();

      if (this.data.length > 0) {
        this.complete_dataLoaded = true;
      }

    } catch (e) {
      console.log(e);
    }
  }

  async reload() {
    await this.initData();

    if (this.pendingTable) {
      this.pendingTable.initCalls();
    }
    this.loadCompletePrints();
    this.progressSpinnerDialogService.end();
  }

  async checkTab(event) {
    if (event.index === 1) {
      await this.loadCompletePrints();
    } else if (event.index === 0) {
      await this.initData();
    }
  }

  async loadCompletePrints() {
    await this.initCompleteData();
    this.completeTable.initCalls();
    this.pendingTable.initCalls();
  }
}
