import { ToastMessagesService } from './../../services/toast-messages.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';

@Component({
  selector: 'app-edit-communication',
  templateUrl: './edit-communication.component.html',
  styleUrls: ['./edit-communication.component.scss']
})
export class EditCommunicationComponent implements OnInit {

  form: FormGroup;
  edit = false;
  dataLoaded = false;
  data;

  constructor(public formBuilder: FormBuilder,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private commonService: CommonService,
              private toastMessagesService: ToastMessagesService) {
      this.form = this.formBuilder.group({
        address: [
          '',
          Validators.compose([
            Validators.required
          ]),
        ],
        phone_number: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]*$'),
          ]),
        ],
        postal_code: [
          '',
          Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]*$'),
          ]),
        ],
        city: [
          '',
          Validators.compose([
            Validators.required
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email
          ]),
        ],
        iban: [
          '',
          Validators.compose([
            Validators.required
          ]),
        ],
      });
  }

  async initData(): Promise<void> {
    const response = await this.commonService.getRequest('communication');

    this.data = response;
  }

  async ngOnInit(): Promise<void> {

    try {
      await this.initData();
    } catch (error) {
      console.log(error);
    }

    this.form = this.formBuilder.group({
      address: [
        this.data?.address,
        Validators.compose([
          Validators.required
        ]),
      ],
      phone_number: [
        this.data?.phone_number,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      postal_code: [
        this.data?.postal_code,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ]),
      ],
      city: [
        this.data?.city,
        Validators.compose([
          Validators.required
        ]),
      ],
      email: [
        this.data?.email,
        Validators.compose([
          Validators.required,
          Validators.email
        ]),
      ],
      iban: [
        this.data?.iban,
        Validators.compose([
          Validators.required,
        ]),
      ],
    });

    this.dataLoaded = true;
  }


  async submit() {

    this.progressSpinnerDialogService.show();

    try {
      const body = this.form.value;

      let response;

      if (this.data) {
        response = await this.commonService.putRequest(`communication/${this.data.id}`, body);
      } else {
        response = await this.commonService.postRequest('communication', body);
      }
      this.cancelEdit();
      this.toastMessagesService.toastSuccessMessage('Τα στοιχεία επικοινωνίας αποθηκεύτηκαν επιτυχώς.');

      await this.initData();
    } catch (error) {
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
      console.log(error);
    } finally {
      this.progressSpinnerDialogService.end();
    }

  }

  editing() {
    this.edit = true;
  }

  cancelEdit() {
    this.edit = !this.edit;
  }
}
