import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommonService} from 'src/app/services/common.service';
import {ProgressSpinnerDialogService} from 'src/app/services/progress-spinner-dialog.service';
import {ToastMessagesService} from 'src/app/services/toast-messages.service';
import {EditShippingsDialogComponent} from '../edit-shippings-dialog/edit-shippings-dialog.component';

@Component({
  selector: 'app-edit-payments-dialog',
  templateUrl: './edit-payments-dialog.component.html',
  styleUrls: ['./edit-payments-dialog.component.scss']
})
export class EditPaymentsDialogComponent implements OnInit {

  shippings = new FormControl([], Validators.required)
  form: FormGroup
  iban = '';
  loaded = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<EditShippingsDialogComponent>,
    private progressSpinnerDialogService: ProgressSpinnerDialogService,
    private commonService: CommonService,
    public formBuilder: FormBuilder,
    private toastMessagesService: ToastMessagesService) {
  }

  async ngOnInit(): Promise<void> {
    const communication = await this.commonService.getRequest('communication');
    this.loaded = true;
    this.iban = communication.iban;
    this.form = this.formBuilder.group({
      description: [this.data.description, Validators.required],
      price: [this.data.price, Validators.required],
      is_active: [this.data.is_active],
      has_shipping: [this.data.has_shipping],
      notes: [this.data.notes],
    })
    if (this.data.has_shipping) {
      let selected = this.data.shippings
      this.shippings.setValue(selected)
    }
    this.form.valueChanges.subscribe((e) => {
      if (!e.has_shipping)
        this.shippings.clearValidators()
      else
        this.shippings.setValidators([Validators.required])
      this.shippings.updateValueAndValidity({emitEvent: false});
    })
  }

  compareFn(o1: any, o2: any) {
    return o1 == o2.id;
  }

  async submit() {
    try {
      this.progressSpinnerDialogService.show();
      const body = {
        id: this.data.id,
        ...this.form.value,
        shippings: this.shippings.value
      };
      if (!this.form.value.has_shipping) {
        body.shippings = []
      }
      if (this.data.is_new) {
        await this.commonService.postRequest('payment', body);
      } else {
        await this.commonService.putRequest('payment', body);
      }

      this.toastMessagesService.toastSuccessMessage('Ο τρόπος πληρωμής αποθηκεύτηκε επιτυχώς');

      this.dialogRef.close(body);
      this.progressSpinnerDialogService.end();

    } catch (e) {
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTitle() {
    if (this.data.is_new) {
      return 'Προσθήκη Τρόπου Πληρωμής';
    }

    return 'Ενημέρωση Τρόπου Πληρωμής';
  }

  addIban() {
    const notes = this.form.getRawValue().notes;
    if (notes) {
      this.form.get('notes')?.setValue(notes + " " + this.iban);
    } else {
      this.form.get('notes')?.setValue(this.iban);
    }
  }
}
