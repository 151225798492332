import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Users} from '../../interfaces/users';
import {TransactionService} from '../../services/transaction.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {
  users: Users[] = [];
  search: string;
  searchItems = [];
  searchEnabled: boolean;
  displayedColumns: string[] = ['id', 'first_name', 'last_name', 'username', 'email', 'phone', 'address', 'prints', 'edit'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  noData: boolean;
  dataLoaded: boolean;
  card: any;
  allPrints;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService,
              private transactionService: TransactionService,
              private dialog: MatDialog, private router: Router) {
    this.noData = true;
    this.searchEnabled = false;
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      this.users = await this.userService.getUsers();

      const result = this.users.filter(user => user.is_guest_user !== 1);

      if (result.length > 0) {
        this.noData = false;
      }
      const info = [];
      this.card = [];
      this.card = result;
      for (const u of result) {
        const card = {
          content: u,
          //     collapsed: false
        };
        //  this.card.push(card);
        info.push(card.content);
      }
      this.dataSource.data = info;
      this.dataLoaded = true;
    } catch (e) {
      console.log(e);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // openDialog(user) {
  //     const dialogConfig = new MatDialogConfig();
  //     dialogConfig.data = user;
  //     this.dialog.open(UsersInfoComponent, dialogConfig);
  // }

  // calculateOrdersTotal(user) {
  //   const currentUserPrints = this.totalPrints.filter(id => id.user_id === user.id);
  //   return currentUserPrints.length;
  // }

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getUserPrintOrders(id) {
    this.router.navigate(['/home/usersPrint'], {queryParams: {id}});
  }

  public setUserAdmin(element) {
    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: {
        title: 'Ορισμός ως Διαχειριστή',
        message: `Είστε σίγουρος ότι θέλετε να ορίσετε το χρήστη ${element.first_name} ${element.last_name} ως Διαχειριστή;`,
        object: {id: element.id}
      }
    });
  }

  // searchUsers() {
  //   // await this.userService.searchUser(this.search)
  //   this.searchEnabled = true;
  //   const items = this.users.filter(user => user.username === this.search);
  //   this.card = [];
  //   this.searchItems = [];
  //   for (const u of items) {
  //     const model = {
  //       id: u.id,
  //       name: u.username,
  //       aem: u.profile.aem,
  //       phone: u.profile.phone,
  //       address: u.profile.address,
  //       postalCode: u.profile.postalCode,
  //       city: u.profile.city
  //     };
  //     const card = {
  //       content: model,
  //       collapsed: false
  //     };
  //     this.searchItems.push(card);
  //   }
  // }

}
