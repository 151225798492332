import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OptionsDialogComponent } from '../options-dialog/options-dialog.component';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss']
})
export class PaymentDialogComponent implements OnInit {

  data: any;
  constructor(public dialogRef: MatDialogRef<OptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
      this.data = data;
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
