import { ToastMessagesService } from './../../services/toast-messages.service';
import {Component, OnInit} from '@angular/core';
import {EditService} from '../../services/edit.service';
import {MatDialog} from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {
  categories: any;
  properties: any;
  propertiesModel: any;
  newCategory: string;
  newProperty: string;
  dataLoaded: boolean;
  mainCategories = [];
  otherCategories = [];

  constructor(private editService: EditService,
              private toastMessagesService: ToastMessagesService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService) {
    this.newCategory = '';
    this.newProperty = '';
    this.dataLoaded = false;
  }

  async ngOnInit() {
    try {
      await this.initCategories();
    } catch (e) {
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
      console.log(e);
    }
  }

  async initCategories() {
    try {
      this.categories = await this.editService.getCategories();
      this.properties = await this.editService.getProperties();

      this.propertiesModel = [];
      this.mainCategories = [];
      this.otherCategories = [];
      for (const category of this.categories) {
        const options = this.properties.filter(item => item.category_id === category.id);
        const model = {
          id: category.id,
          description: category.description,
          properties: options,
          edit: false,
          details: category.details,
          main: category.is_main
        };
        this.propertiesModel.push(model);
      }
      for (const item of this.propertiesModel) {
        if (item.main === 0) {
          this.mainCategories.push(item);
        } else {
          this.otherCategories.push(item);
        }
      }
      // console.log(this.mainCategories);
      // console.log(this.otherCategories);
      this.dataLoaded = true;
    } catch (e) {
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
      console.log(e);
    }
  }

  async reloadMain(event, update = false) {

    await this.initCategories();
    this.progressSpinnerDialogService.end();
    if (update) {
      if (event.property) {
        this.toastMessagesService.toastSuccessMessage('Η επιλογή αποθηκεύτηκε επιτυχώς.');
      } else {
        this.toastMessagesService.toastSuccessMessage('Η κατηγορία αποθηκεύτηκε επιτυχώς.');
      }
    } else {
      if (event.property) {
        this.toastMessagesService.toastSuccessMessage('Η επιλογή δημιουργήθηκε επιτυχώς.');
      } else {
        this.toastMessagesService.toastSuccessMessage('Η κατηγορία δημιουργήθηκε επιτυχώς.');
      }
    }
  }

  async reloadOther(event, update = false) {

    await this.initCategories();
    this.progressSpinnerDialogService.end();

    if (update) {
      if (event.property) {
        this.toastMessagesService.toastSuccessMessage('Η επιλογή αποθηκεύτηκε επιτυχώς.');
      } else {
        this.toastMessagesService.toastSuccessMessage('Η κατηγορία αποθηκεύτηκε επιτυχώς.');
      }
    } else {
      if (event.property) {
        this.toastMessagesService.toastSuccessMessage('Η επιλογή δημιουργήθηκε επιτυχώς.');
      } else {
        this.toastMessagesService.toastSuccessMessage('Η κατηγορία δημιουργήθηκε επιτυχώς.');
      }
    }
  }

  async reloadMainDelete() {
    await this.initCategories();
    this.progressSpinnerDialogService.end();

  }

  async reloadOtherDelete() {
    await this.initCategories();
    this.progressSpinnerDialogService.end();

  }

  // isButtonDisabled() {
  //   return this.newCategory.length === 0;
  // }
  //
  // async addNewCategory() {
  //   try {
  //     await this.editService.addNewCategory(this.newCategory);
  //     await this.initCategories();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  //
  // addNewProperty(id) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = id;
  //   this.dialog.open(AddPropertyDialogComponent, dialogConfig);
  // }
  //
  // editPropertyDialog(item) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = item;
  //   const dialogRef = this.dialog.open(EditPropertyDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(async data => {
  //     if (data) {
  //       await this.initCategories();
  //     }
  //   });
  // }
  //
  // async editCategory(item) {
  //   try {
  //     const model = {
  //       id: item.id,
  //       description: item.description
  //     };
  //     await this.editService.editCategory(model);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  //
  // editProperty(item) {
  //   try {
  //     const model = {
  //       id: item.id,
  //       description: item.description,
  //       price: item.price,
  //       is_active: item.is_active,
  //       category_id: item.category_id
  //     };
  //     this.editPropertyDialog(model);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

}
