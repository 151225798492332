import {FormBuilder, FormGroup, Validators, FormArray, ValidatorFn, ValidationErrors} from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { EditService } from '../../services/edit.service';


@Component({
  selector: 'app-edit-property-dialog',
  templateUrl: './edit-property-dialog.component.html',
  styleUrls: ['./edit-property-dialog.component.scss']
})
export class EditPropertyDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditPropertyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data,
              private editService: EditService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private formBuilder: FormBuilder) {
    this.data = data;
  }

  get scaleFields() {
    return this.form.get('scales') as FormArray;
  }
  // id: number
  // name: string
  // price: string
  // isChecked: any = true
  // has_scale: boolean = false
  // from: number
  // to: number
  // scale_price: number
  data: any;
  propObj: any;
  form: FormGroup;
  myForm: FormGroup;
  mode = true;
  success = false;
  initValue = 0;
  index = 0;
  validScale = false;
  maxIndex = -1;
  deletable = [true];


  ngOnInit(): void {
    this.mode = !this.data.id;
    this.form = this.formBuilder.group({
      description: ['', Validators.required],
      price: ['', Validators.required],
      is_active: [false],
      has_scale: [false],
      scales: this.formBuilder.array([]),
    });

    if (!this.mode){
      this.data.scales?.forEach(() => this.addScale());
      this.form.patchValue(this.data);
      this.maxIndex = this.scaleFields.value.length - 1
    }
  }

  public comparisonValidator(): ValidatorFn{
    return (group: FormGroup): ValidationErrors => {
      const control1 = group.controls.from;
      const control2 = group.controls.to;
      if (control1.value >= control2.value) {
        control2.setErrors({greaterThan: true});
      } else {
        control2.setErrors(null);
      }
      return;
    };
  }

  public scaleCheck(i){
    if (this.form.value.scales.length > i + 1) {
      this.scaleFields.controls[i+1].get('from').setValue(this.form.value.scales[i].to + 1)
      this.form.updateValueAndValidity();
    }
  }

  increment(){
    this.maxIndex++;
  }

  addScale(){
    let start = 0;
    let end = 0;
    if (this.scaleFields.value[this.index - 1]) {
      start = this.scaleFields.value[this.index - 1].to + 1
      end = this.scaleFields.value[this.index - 1].to + 2
    }
    this.myForm = this.formBuilder.group({
      from: [start, Validators.required],
      to: [end],
      price: [, Validators.required]
    });
    const scale = this.myForm;
    this.scaleFields.push(scale);
    // if (this.data && this.data.scales) {
    //   this.initValue = this.data.scales[this.index].to + 1;
    // } else if (this.scaleFields.value.length > 0) {
    //   this.initValue = this.scaleFields.value[this.index].to + 1;
    // }
    this.index++;

    for (let i = 0; i < this.scaleFields.value.length - 1; i++) {
      this.scaleFields.controls[i].setValidators(this.comparisonValidator());
    }
  }

  deleteScale(i) {
    if (i === this.maxIndex && i > 0){
      this.scaleFields.removeAt(i);
      this.maxIndex--;
      this.initValue = this.form.value.scales[i - 1].to + 1;
      this.deletable[this.maxIndex] = true;
    }
    if (i === this.maxIndex && i === 0){
      this.scaleFields.controls[i].setValidators(null);
      this.scaleFields.removeAt(i);
      this.maxIndex = -1;
      this.initValue = 0;
    }
    if (i === 0){
      this.index = 0;
    }
  }

  deleteAll(){
    let i = this.scaleFields.length;
    while (this.scaleFields.length > 0){
      this.scaleFields.removeAt(i);
      i--;
    }
    this.maxIndex = -1;
    this.initValue = 0;
  }

  async addProperty() {
    this.progressSpinnerDialogService.show();
    if (!this.form.value.has_scale){
      delete this.form.value.scales
    }
    try {
      if (this.mode){
        this.form.value.category_id = this.data;
        await this.editService.addNewProperty(this.form.value);
      }
      else
      {
        this.form.value.category_id = this.data.category_id;
        this.form.value.id = this.data.id;
        await this.editService.editProperty(this.form.value);
      }
      this.success = true;
    } catch (e) {
      this.progressSpinnerDialogService.end();
    }
    this.progressSpinnerDialogService.end();
  }

  closeDialog() {
    this.dialogRef.close(this.form.value);
  }

}
