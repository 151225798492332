<div fxLayout="row" class="mat-title" style="margin: 20px" fxLayoutAlign="center center center">Επεξεργασία στοιχείων επικοινωνίας</div>
<mat-divider style="padding-bottom: 20px"></mat-divider>

<div class="center" style="padding-bottom: 20px">
    <div fxLayoutAlign="start">
      <mat-icon class="icon-config info-color" style="margin-right: 5px;">info</mat-icon>
      <span style="margin-top: 3px;">
        Εδώ μπορείτε να δείτε και να επεξεργαστείτε τα στοιχεία επικοινωνίας του βιβλιοπωλείου.
      </span>
    </div>
</div>

<div *ngIf="dataLoaded" class="container">
    <form [formGroup]="form" style="width: 100%">
        <div fxHide.lt-sm fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="40px" fxLayoutAlign.xs="start" fxLayoutAlign.md="start"
        fxLayoutAlign.gt-xs="space-evenly start" style="margin-top: 2%; font-size: medium">
            <div fxLayout="column" fxLayoutGap="10px">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
                    <div style="width: 100px;">Διεύθυνση:</div>
                    <mat-form-field fxFlex>
                        <label>
                            <input matInput formControlName="address" [readonly]="!edit">
                        </label>
                        <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('address').setValue('')"
                            matSuffix>clear</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
                    <div style="width: 100px">Πόλη:</div>
                    <mat-form-field fxFlex>
                      <label>
                        <input matInput formControlName="city" [readonly]="!edit">
                      </label>
                      <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('city').setValue('')"
                      matSuffix>clear</mat-icon>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
                    <div style="width: 100px;">T.K:</div>
                    <mat-form-field fxFlex>
                      <label>
                        <input matInput formControlName="postal_code" [readonly]="!edit">
                      </label>
                      <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('postal_code').setValue('')"
                      matSuffix>clear</mat-icon>
                        <mat-error *ngIf="form.get('postal_code').invalid">
                            Πληκτρολογήστε ενα έγκυρο T.K
                        </mat-error>
                    </mat-form-field>
                  </div>
            </div>
            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center center">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center center">
                  <div style="width: 100px;">Τηλέφωνο:</div>
                  <mat-form-field fxFlex>
                    <label>
                      <input matInput formControlName="phone_number" [readonly]="!edit">
                    </label>
                    <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('phone_number').setValue('')"
                    matSuffix>clear</mat-icon>
                    <mat-error *ngIf="form.get('phone_number').invalid">
                        Πληκτρολογήστε ενα έγκυρο τηλέφωνο
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
                  <div style="width: 100px">Email:</div>
                  <mat-form-field fxFlex>
                    <label>
                      <input matInput formControlName="email" [readonly]="!edit">
                    </label>
                    <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('email').setValue('')"
                    matSuffix>clear</mat-icon>
                    <mat-error *ngIf="form.get('email').invalid">
                        Πληκτρολογήστε μια έγκυρη διεύθυνση email
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center center">
                  <div style="width: 100px">IBAN:</div>
                  <mat-form-field fxFlex>
                    <label>
                      <input matInput formControlName="iban" [readonly]="!edit">
                    </label>
                    <mat-icon *ngIf="edit" style="cursor: pointer;" (click)="form.get('iban').setValue('')"
                    matSuffix>clear</mat-icon>
                  </mat-form-field>
                </div>
            </div>
        </div>
    </form>

    <div style="margin-top:1%;" fxLayoutGap="40px">
        <button *ngIf="!edit" style="width: 220px" class="action-btn custom-btn" mat-raised-button (click)="editing()">Επεξεργασία</button>
        <button *ngIf="edit" style="width: 220px" class="action-btn custom-btn" mat-raised-button [disabled]="form.invalid" (click)="submit()">Αποθήκευση</button>
        <button *ngIf="edit" mat-icon-button style="margin: 10px" (click)="cancelEdit()">
          <mat-icon>clear</mat-icon>
        </button>
    </div>
</div>
