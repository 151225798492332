import { TotalPriceDialogComponent } from './../total-price-dialog/total-price-dialog.component';
import { ConDialogComponent } from './../con-dialog/con-dialog.component';
import { PaymentDialogComponent } from './../payment-dialog/payment-dialog.component';
import {AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef} from '@angular/core';
import {OptionsDialogComponent} from '../options-dialog/options-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {UserService} from '../../services/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {TransactionService} from '../../services/transaction.service';
import {CommonService} from '../../services/common.service';
import {ToastMessagesService} from '../../services/toast-messages.service';
import * as moment from 'moment';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import * as XLSX from 'xlsx';
import { MatTableExporterDirective } from 'mat-table-exporter';

@Component({
  selector: 'app-main-details-tab',
  templateUrl: './main-details-tab.component.html',
  styleUrls: ['./main-details-tab.component.scss']
})
export class MainDetailsTabComponent implements OnInit, AfterViewInit {
  @Input() info;
  @Input() status;
  @Output() updated = new EventEmitter<string>();
  users: any;
  pendingDisplayedColumns: string[] = ['id', 'order_id', 'date', 'shipping', 'name', 'options', 'payment', 'total_pages', 'price', 'payment_description', 'paid_description', 'paid', 'state'];
  completeDisplayedColumns: string[] = ['id', 'order_id', 'date', 'date_completed', 'shipping', 'name', 'options', 'payment', 'total_pages', 'price', 'paid'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  model: any;
  name: any;
  printTable = [];
  search: string;
  mode: string;
  loaded: boolean;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table') table: ElementRef;
  @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

  hiddenColumns = [];

  constructor(private dialog: MatDialog,
              private userService: UserService,
              private transactionService: TransactionService,
              private commonService: CommonService,
              public toastMessagesService: ToastMessagesService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService, ) {
  }

  async ngOnInit() {

    if (this.status === 1) {
      this.hiddenColumns = [4, 5, 9, 10];
    } else {
      this.hiddenColumns = [5, 6, 8];
    }

    try {
      await this.initCalls();
    } catch (e) {
      // console.log(e);
    }
  }

  async initCalls() {
    try {
      this.loaded = false;
      // //console.log(this.info);
      this.users = await this.userService.getUsers();
      this.printTable = [];

      if (this.info.length > 0) {
        for (const tr of this.info) {
          this.model = [];
          for (const u of this.users) {
            this.name = this.users.filter(user => user.id === tr.user_id);
          }

          this.model = {
            id: tr.id,
            order_id: tr.order_id,
            name: tr.user_fullname,
            date: moment(tr.date_created, 'DD/MM/YYYY'),
            date_completed:  moment(tr.date_completed, 'DD/MM/YYYY'),
            shipping: tr.shipping_method_description,
            price: tr.total_price,
            final_total: tr.final_total ? tr.final_total : tr.total_price,
            total_pages: tr.total_pages,
            final_total_description: tr.final_total_description,
            printOptions: tr.details,
            status: tr.status_description,
            status_id: tr.status_id,
            collapsed: false,
            phone_number: this.name[0].phone_number,
            address: this.name[0].address,
            postal_code: this.name[0].postal_code,
            is_paid: tr.is_paid === null ? 0 : tr.is_paid,
            paid_description: tr.is_paid_description === null ? 'Αναμονή Πληρωμής' : tr.is_paid_description,
            payment_description: tr.payment_description
          };

          this.printTable.push(this.model);
        }
      }
      // console.log(this.printTable);
      this.loaded = true;

      this.dataSource.data = this.printTable;

      this.dataSource.paginator = this.paginator;

      this.dataSource.sort = this.sort;

    } catch (e) {
      // console.log(e);
    }
  }

  ngAfterViewInit() {

  }


  async showDetails(id, item2, item3, item4, item5) {

    let options = [];
    try {
      this.progressSpinnerDialogService.show();

      const response = await this.commonService.getRequest(`request/details/${id}`);
      this.progressSpinnerDialogService.end();

      options = response;
    } catch (error) {
      this.progressSpinnerDialogService.end();

    }

    const item = {
      options: options,
      dateC: item2,
      address: item3,
      postal_code: item4,
      phone_number: item5,
      notes: options[1].notes
    };
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = item;
    const myDialog = this.dialog.open(OptionsDialogComponent, dialogConfig);
  }

  async editRequestConfirm(id, status) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Επιβεβαίωση Ολοκλήρωσης',
      message: 'Είστε σίγουρος ότι αυτή η εκτύπωση ολοκληρώθηκε;'
    };
    const dialog = this.dialog.open(ConDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.editRequest(id, status);
      }
    });
  }

  async editRequest(id, status) {
    try {
      // let mode = ``;
      // let mode = '';
      if (status === 1) {
        // mode = `edit`;
        this.mode = 'complete';
      }
    //   } else if (status === 2) {
    //     // mode = `complete`;
    //   this.mode = 'complete';
    // }
      const item = {
        id: id
      };

      this.progressSpinnerDialogService.show();
      await this.transactionService.editRequest(item, this.mode);
      this.toastMessagesService.toastSuccessMessage('Η ενέργεια ολοκληρώθηκε επιτυχώς.');
      this.progressSpinnerDialogService.end();
      this.updated.emit(id);
     // this.refresh();
      // if (this.mode === 'complete'){
      //
      //   this.dataSource.data.splice((this.dataSource.data.find(r => r.id === id)) , 1);
      //   // const req = this.dataSource.data.find(r => r.id === id);
      //   // this.dataSource.data.splice( this.dataSource.data.indexOf(req), 1);
      // }
    } catch (e) {
      this.progressSpinnerDialogService.end();
      // console.log(e);
    }
  }

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();
  }

  refresh(): void {
    window.location.reload();
  }

  async downloadFile(id: number, name: string) {
    try {
      await this.commonService.downloadFile(id, name);
    } catch (e) {
      // console.log(e);
      this.toastMessagesService.toastErrorMessage(e.message);

    }
  }

  async showPayment(element) {
    try {
      this.progressSpinnerDialogService.show();
      const response = await this.commonService.getRequest(`request/${element.id}/document`);
      this.progressSpinnerDialogService.end();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = response[0];
      const myDialog = this.dialog.open(PaymentDialogComponent, dialogConfig);
    } catch (error) {
      this.progressSpinnerDialogService.end();

    }
  }

  async pay(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Επιβεβαίωση Πληρωμής',
      message: 'Είστε σίγουρος ότι αυτή η εκτύπωση πληρώθηκε;'
    };
    const dialog = this.dialog.open(ConDialogComponent, dialogConfig);

    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.payItem(item);
      }
    });
  }

  async payItem(item) {
    try {
      const body = {
        id: item.id
      };
      this.progressSpinnerDialogService.show();
      await this.commonService.putRequest('request/pay', body);
      item.is_paid = 1;
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastSuccessMessage('Η εκτύπωση αποθηκεύτηκε επιτυχώς');
    } catch (error) {
      this.progressSpinnerDialogService.end();
    }
  }

  // async editRequestTotalPrice(request) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = {
  //     request
  //   };
  //   const dialog = this.dialog.open(TotalPriceDialogComponent, dialogConfig);

  //   dialog.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.updated.emit()
  //     }
  //   });
  // }

  getExportName() {
    if (this.status === 1) {
      return 'Λίστα εκτυπώσεων σε αναμονή';
    } if (this.status === 3) {
      return 'Λίστα ολοκληρωμένων εκτυπώσεων';
    }
  }

  // async export() {
  //   try {
  //     await this.transactionService.export(this.status);
  //   } catch (error) {
  //     console.log(error)
  //   }
  //   this.exporter.exportTable('xlsx', {
  //     fileName: this.getExportName(),
  //     columnWidths: [10, 20, 25, 40, 20, 20, 30, 40],
  //     },
  //   );
  // }
}
