import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { EditPaymentsDialogComponent } from '../edit-payments-dialog/edit-payments-dialog.component';
import { NewShippingsDialogComponent } from '../new-shippings-dialog/new-shippings-dialog.component';

@Component({
  selector: 'app-edit-payments',
  templateUrl: './edit-payments.component.html',
  styleUrls: ['./edit-payments.component.scss']
})
export class EditPaymentsComponent implements OnInit {

  dataLoaded = false;
  index = -1;
  payments: any = [];
  shippings = [];

  constructor(private commonService: CommonService, private dialog: MatDialog) { }

  async ngOnInit(): Promise<void> {
    await this.loadPayments();
    // console.log(this.shippings)
  }

  async loadPayments() {
    try {
      const payments = await this.commonService.getRequest('payment');
      const shippings = await this.commonService.getRequest('shipping');
      this.payments = payments;
      this.shippings = shippings
      this.dataLoaded = true;
    } catch (error) {

    }
  }

  async newPaymentModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      description: '',
      price: 0,
      is_active: 0,
      has_shipping: 0,
      availableShippings: this.shippings,
      is_new: true
    };
    const dialogRef = this.dialog.open(EditPaymentsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadPayments();
      }
    });
  }

  canDelete(item) {
    return false;
  }

  isEdit(index) {
    return index === this.index;
  }

  async editDialog(item) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = item;
    dialogConfig.data.availableShippings = this.shippings
    const dialogRef = this.dialog.open(EditPaymentsDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.loadPayments();
        item = data;
      }
    });
  }

}
