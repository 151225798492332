import { ForgotPasswordDialogComponent } from './../forgot-password-dialog/forgot-password-dialog.component';
import {Component, HostListener, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { ProgressSpinnerDialogService } from 'src/app/services/progress-spinner-dialog.service';
import { MatDialog } from '@angular/material/dialog';
import {ToastMessagesService} from '../../services/toast-messages.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  hide: boolean;
  error: boolean;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.inputsValid()) {
        this.adminLogin();
      }
    }
  }

  constructor(private adminService: AdminService,
              private progressSpinnerDialogService: ProgressSpinnerDialogService,
              private toastMessagesService: ToastMessagesService,
              private dialog: MatDialog,
              private router: Router) {
    this.hide = true;
    this.error = false;
    this.username = '';
    this.password = '';

  }

  async ngOnInit(): Promise<void> {

    try {
      this.progressSpinnerDialogService.show();
      const user = await this.adminService.getMyInfo(false);
      this.progressSpinnerDialogService.end();

      if (user) {
        this.router.navigateByUrl('home/dashboard');
      }
    } catch (error) {
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
    }

  }

  inputsValid(){
    if (this.username === '' || this.password === '') {
      return false;
    }else {
      return true;
    }
  }

  async adminLogin() {
    try {
      this.progressSpinnerDialogService.show();
      await this.adminService.adminLogin(this.username, this.password);
      this.progressSpinnerDialogService.end();
    } catch (e) {
      this.progressSpinnerDialogService.end();
      this.toastMessagesService.toastErrorMessage('Υπήρξε κάποιο πρόβλημα');
    }
  }

  async forgotPassword() {
    const dialog = this.dialog.open(ForgotPasswordDialogComponent);
  }
}
